import 'raty-js'

document.addEventListener "turbolinks:load", () ->
  $(".raty").raty half: true, path: "/assets"
  $(".raty-ro").raty half: true, path: "/assets", space: false, readOnly: true, score: -> $(this).attr('data-rating')
  $("form", ".order-item-rating").submit (e) ->
    e.preventDefault()
    form = $(this).parents("form:first")
    $(".raty", ".order-item-rating").each -> 
      $("<input>", type: "hidden", name: $(this).attr('id'), value: $("input[name=score]", this).val()).appendTo(form)
