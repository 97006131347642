import "blueimp-file-upload"
import { Cart } from '../legacy/cart'
import './budget.coffee'
import './interactions.coffee'
import './ratings.coffee'


document.addEventListener("turbolinks:load", function() {
  // ServiceType.menu.bindOpen();
  Cart.init();

  $('#user_person_type').change(function(){
    $(':input', '.pf, .pj').attr('disabled', 'disabled');
    if (this.value == '1'){
      $('.pf').hide();
      $('.pj').show().find(':input').removeAttr('disabled');
      $('#user_cnpj').mask("99.999.999/9999-99");
    } else {
      $('.pj').hide();
      $('.pf').show().find(':input').removeAttr('disabled');;
      $('#user_cpf').mask("999.999.999-99");
    }
  }).change();
  
  //*** Máscaras padrões
  $(":input.cpf").mask("999.999.999-99");
  $(":input.cnpj").mask("99.999.999/9999-99");
  $(':input.phone').mask("(99) 99999-9999");
  $(':input.date, :input.date-with-no-calendar').mask("99/99/9999");
  $(':input.cep').mask("99999-999");

  $(':input.valor-moeda').maskMoney({ showSymbol: false, decimal:",", thousands:"" });

  $('#user_has_moip_account').change(function(){
    $('#moip_account_fields').toggle('slideDown');
  })

  $('#user_be_supplier').change(function(){
    $('.supplier_fields').toggle('slideDown');
  });

  $('input[name=by-item-export-xls]').click(function(){
    form = $(".filtrar-pedidos form");    
    form.append($("<input>", {type:"hidden",name:"export",value:"true"}));
    form.submit();
    form.append($("<input>", {type:"hidden",name:"export",value:"false"}));
  });

  $('input[name=by-taxes-export-xls]').click(function(){
    form = $(".filtrar-pedidos form");    
    form.append($("<input>", {type:"hidden",name:"export",value:"true"}));
    form.submit();
    form.append($("<input>", {type:"hidden",name:"export",value:"false"}));
  });



  $('.payment-types .types input[type=radio]').change(function(){
    if ($(this).parents('.creditcard').length > 0){
      $('.creditcard-data').slideDown(300);
    } else {
      $('.creditcard-data').slideUp(300);
    }
  });


  const budget = new window.Budget();
  budget.url = $('#section-budget').data('upload-url')
  budget.initialize();
  console.info('Budget loaded!');

  // $('.file_upload').change(function () {
  //   $(this).closest('form').submit();
  // });


  if ($("#messages").data('user') == 'client') {
    window.interactions = new Interactions();
    window.interactions.initialize();

    $("#button-approve").click(function() {
      $.post($("#messages").data('interactions-url') + '.js', {
        purchase_id: $('#messages').data('purchase-id'),
        'interaction[status]':'approved'
      })
      return false;
    });
    $("#button-adjust").click(function() {
      $(".approve-form").hide();
      $(".adjust-form, .message-form").show();
      $("#interaction_status").val("execution")
      return false;
    });
  } else if ($("#messages").data('user') == 'supplier') {
    window.interactions = new Interactions;
    window.interactions.initialize();
  }
});
