export const Cart = {
  link: '.li-cart a.showcart',
  content: '#hidecart',
  container: '.li-cart',
  open: function(){
    $(Cart.link).unbind();
    $(Cart.content).slideDown(200, function(){ Cart.bindClose(); });
    $(Cart.container).css({"background-color":"white","border-right":"2px solid #C7C7C7"});
  },
  close: function(){
    $(Cart.link).unbind();
    $(Cart.content).slideUp(500, function(){
      $(Cart.container).css({"background-color":"#F3F0E6","border-right":""});
      Cart.bindOpen();
    });
  },
  bindOpen: function(){
    $(Cart.link).on('mouseenter click', function(){ Cart.open(); });
  },
  bindClose: function(){
    $(Cart.container).unbind().on('mouseleave', function(){ Cart.close(); });
    $(Cart.container).click(function(){
      $("html").unbind();
      setTimeout(function(){
        $("html").one('click', function(){ Cart.close(); });
      }, 200);
    }).click();
  },
  init: function(){
    $(Cart.link).attr('href', 'javascript:void(0);');
    Cart.bindOpen();
  }
}
