class window.AutoScroller

  constructor: (container) ->
    scroller = this
    @container = $(container)
    @container.on 'scroll', (e) ->
      scroller.unlocked = $(this).scrollTop() < (this.scrollHeight - $(this).innerHeight())
      
  start: ->    
    scroller = this
    @interval = setInterval((->
      scroller.container.scrollTop(9999) unless scroller.unlocked
    ), 1000)
    
  stop: ->
    clearInterval @interval
    
  forceScroll: ->
    @container.scrollTop(9999)

