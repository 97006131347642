import './auto-scroller.coffee'
import './poirot.js'

class window.Interactions

  initialize: ->
    @scroller = new window.AutoScroller(".inside-pendencias-b")
    @scroller.forceScroll()
    @scroller.start()
    @setupForm()
    @startTimer()

  update: ->
    # clearInterval(@interval)
    self = this
    $.ajax 
      url: "#{location.pathname}.js"

  addMessage: (interaction) ->
    # @scroller.forceScroll()
    $(".interaction_message textarea").focus()

  startTimer: ->
    self = this
    @interval = setInterval((-> self.update()), 5000)

  validate: ->
    $.trim($(".interaction_message textarea").val()) isnt ""

  onAddFile: (e, data) ->
    self = this
    $("input[name=commit]", @messageForm()).off().on "click", (e) ->
      e.preventDefault()
      if self.validate()
        $('.loading').show()
        data.submit() 
      
  onComplete: (interactions) ->
    $('.loading').hide()
    @update()
    if interactions.errors 
      ul = $('<ul>')
      $(interactions.errors).each ->
        ul.append($('<li>').text(this))

      $('.rate-errors').html(ul)

    else
      @forms.each -> @reset()
    
    @setupForm()
    
  ajaxSubmit: (e) ->
    e.preventDefault()
    return unless @validate()
    $.post("#{location.pathname}.js", @messageForm().serialize())
    
  messageForm: ->
    $(".order-item-rating form:first .raty").each ->
      $(":input:hidden:first", this).attr('name', "rate[" + $(this).attr('id') + "]")
    $(".chat form:first")
    
  setupForm: ->
    # $(".raty").raty half: true, path: "/assets"
    @forms = $("form.new_interaction:first")
    $("input[name=commit]", @forms).off().on "click", $.proxy(@ajaxSubmit, this)
    @forms.fileupload
      replaceFileInput: false
      url: "#{location.pathname}.js"
      add: $.proxy(@onAddFile, this)
      done: $.proxy(@onComplete, this)

