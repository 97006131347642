import { I18n } from './i18n.coffee'

class window.Budget
  evaCheckInterval: 1500
  form: "form.fileupload"
  url: ''
  
  initialize: ->
    @buttonSave().hide()
    $(@form).fileupload
      replaceFileInput: false
      url: @url
      add: $.proxy(@onAddFile, this)
      done: $.proxy(@onDoneUploading, this)
    $('.button--buy').on "click", (e) =>
      $(@form).submit();
    
  onAddFile: (e, data) -> 
    data.submit()
    @fileUploadStarted `(this)`

    #  $("button.send-file", @form).show().off().on "click", (e) =>
    #    e.preventDefault()
    #    data.submit()
    #    @fileUploadStarted `(this)`

  onDoneUploading: (e, data) ->
    unless data.result.status is "ok"
      @hideLoadingIndicators()
      return @showUploadingError(data)
    $(@form).append $("<input>", type: "hidden", name: "budget_id", value: data.result.budget_id)
    @fileProcessingStarted data.result.budget_id
    
  evaUrl: ->
    location.pathname.replace(/\d+$/, '')+"check_file_processing" #TODO: fazer de forma dinâmica
  
  buttonSave: -> $(":submit[name=commit]", @form)

  fileProcessingStarted: (budgetId) ->
    $(".message", @form).html I18n.t("budget.processing_file")
    @budgetId = budgetId
    @checkEva()

  fileProcessingFinished: (data) ->
    @hideLoadingIndicators()
    $(":file", @form).show()

  fileProcessingSuccess: (data) ->
    @fileProcessingFinished()
    $(".section-payment__box--new").addClass("d-none");
    $(".section-payment__box--finished").removeClass("d-none");
    $(".section-payment_item_form--load").addClass("d-none");
    $(".section-payment_item_form--remove").removeClass("d-none");

    @buttonSave().show()
    $(".budget-price").html(data.price).parents('.hide').removeClass('hide')
    $(".section-payment__item__file").html(data.file_name);
    $(".section-payment__item__file").attr('href', data.file_url);
    unless data.minimum_achieved
      $(".budget-minimum_price").html(data.minimum_price).parents('.hide').removeClass('hide')
    
  checkEva: ->
    $.ajax
      url: @evaUrl()
      dataType: "json"
      type: "post"
      data: { budget_id: @budgetId }
      success: (data) =>
        if data.status is "ok" then @fileProcessingSuccess data
        else
          setTimeout((=> @checkEva()), @evaCheckInterval)

  showUploadingError: (data) ->
    $(@form).find(":file").show()
    $(".errors").removeClass('d-none');
    $(".errors").html data.result.messages[0]
    
  hideLoadingIndicators: ->
    $(".loading", @form).hide()
    
  fileUploadStarted: (button) ->
    form = $(@form)
    $(".errors").html("").addClass("d-none");
    form.find(".budget-price").html ""
    form.find(":file").hide()
    form.find(".message").html I18n.t("budget.uploading_file")
    form.find(".loading").show()
    $(button).hide()
