#TODO: Falta agrupar as chaves por Idioma. Está correto deixar as chaves de traduação aqui?
export I18n =
  locale: 'ptbr'
  translates:
    ptbr:
      budget:
        uploading_file: "Fazendo upload do arquivo"
        processing_file: "Processando arquivo"
      moip:
        errors:
          title: 'Não foi possível gravar:'
      _send: "Enviar"
      profile:
        avatar:
          large_file: "Não foi possível enviar arquivo.\nVerifique o tamanho do arquivo (máximo 2MB)."
  t: (key) ->
    keys = key.split('.')
    translate = I18n.translates[I18n.locale]
    keys.forEach (k)->
      translate = translate[k]
    translate
