import { I18n } from './i18n'

export const MoIP = {
  settings: {},
  options: {},
  initialize: function(options){
    MoIP.options = $.extend(MoIP.options, options)
    $('.payment-types .types input[type=radio]').change(function(){
      if ($(this).parents('.creditcard').length > 0){
        MoIP.creditcard.calcPlots($(this).attr('instituicao'));
        $('.creditcard-data').slideDown(300);

        if($(this).attr('instituicao')=='AmericanExpress'){
            // amex masking
            $('#moip_validator_number').mask("999999999999999",{placeholder:" "});
            $('#moip_validator_code').mask("9999",{placeholder:" "});
        } else {
            // standard masking
            $('#moip_validator_number').mask("9999999999999999",{placeholder:" "});
            $('#moip_validator_code').mask("999",{placeholder:" "});
        }

      } else {
        $('.creditcard-data').slideUp(300);
      }


      // $("#moip_validator_number").mask("9999999999999999?9999",{placeholder:" "})
      // $("#moip_validator_code").mask("999?9",{placeholder:" "})
    });

  },
  setup: function(){
    var paymentType = $('.payment-types :radio:checked').val();
    switch(paymentType){
      case "billet":
        MoIP.settings = {"Forma": "BoletoBancario"};
        break;
      case "transfer":
        MoIP.settings = {"Forma": "DebitoBancario", "Instituicao": $('.payment-types :radio:checked').attr('instituicao')};
        break;
      case "creditcard":
        MoIP.settings = {
          "Forma": "CartaoCredito",
          "Instituicao": $('.creditcard :radio:checked').attr('instituicao'),
          "Parcelas": $("#moip_validator_plots").val(),
          "CartaoCredito": {
            "Numero": $("#moip_validator_number").val(),
            "Expiracao": $("#moip_validator_expiration_month").val() + "/" + $("#moip_validator_expiration_year").val(),
            "CodigoSeguranca": $("#moip_validator_code").val(),
            "Portador": {
              "Nome": $("#moip_validator_name").val(),
              "DataNascimento": $("#user_birthdate").val(),
              "Telefone": $("#user_phone_number").val(),
              "Identidade": $("#user_cpf").val()
            }
          }
        };
        break;
    }
  },
  send: function(){
    var validate = function() {
      const always = function() { return true }
      const onlyPf = function() { return $("#user_person_type_0").prop('checked') }
      var required = [
        {input: '#user_birthdate', label: '', display: '.error_birthdate', condition: onlyPf },
        {input: '#user_address_zipcode', label: '', display: '.error_zipcode', condition: always},
        {input: '#user_address_street', label: '', display: '.error_street', condition: always},
        {input: '#user_address_number', label: '', display: '.error_number', condition: always},
        {input: '#user_address_district', label: '', display: '.error_district', condition: always},
        {input: '#user_address_state', label: '', display: '.error_state', condition: always},
        {input: '#user_address_city_id', label: '', display: '.error_city', condition: always},
        {input: '#user_phone_number', label: '', display: '.error_phone', condition: always},
      ]
      $('.fieldWithErrors').removeClass('fieldWithErrors');
      for (var i = 0; i < required.length; i++) {
        var item = required[i];

        if ($(item.input).val().length == 0 && item.condition()) {
          $(item.input).addClass('fieldWithErrors');
          $(item.display).html(item.label + ' precisa ser preenchido');
          $(item.display).show();
        } else {
          $(item.input).removeClass('fieldWithErrors');
          $(item.display).hide();
        }
      }
      var valid = ($('.fieldWithErrors').length == 0);

      $('.fieldWithErrors:first').focus();
      return valid;
    }
    if (!validate()) return;

    $('.error_explanation').hide();
    const typeError = ($('.payment-types input:checked').length == 0)
    if (typeError) {
      $('#payment-type-error').show();
      $('html, body').animate({ scrollTop:  $('.payment-types').offset().top - 50 }, 'slow');
      return;
    }


    $('.processing').css('visibility', 'visible')
    $.post(MoIP.options.urlToCreate, $.extend(MoIP.payer(), MoIP.payment()), function(data){
      if (data.errors){
        MoIP.showErrors(data.errors);
       $('.processing').css('visibility', 'hidden')
      } else {
        $('#MoipWidget').attr('data-token', data.transaction.moip_transaction.response_token);
        MoIP.options.urlToShow = data.urlToShow
        MoIP.setup();
        MoipWidget(MoIP.settings);
      }
    })
  },
  success: function(response){
    $.ajax({
      url: MoIP.options.urlToShow,
      dataType: 'script',
      type: 'PUT',
      data: {transaction: MoIP.settings, response: response}
    });
  },
  fail: function(response){
    response = $(response).toArray();
    if (response.length > 0){
      var msg = [];
      response.forEach(function(message){ msg.push(message['Codigo'] + ' - ' + message['Mensagem'])});
      MoIP.showErrors(msg);
    }
    $('.processing').css('visibility', 'hidden')
  },
  showErrors: function(messages){
    const $ul = $('<ul>');
    messages.forEach(function(message){ $ul.append($('<li>').text(message))});
    $('#error_explanation').remove();
    $("#moip-transaction form").prepend(
      $('<div>')
        .attr("id", "error_explanation")
        .addClass('session')
        .append($('<h2>').text(I18n.t('moip.errors.title')))
        .append($ul)
    );
    $('html,body').animate({scrollTop: $('#error_explanation').offset().top + 'px'}, 'fast');
  },
  payer: function(){
    var payer = {};
    $('#moip-transaction .edit_user :input').serializeArray().forEach(function(field){
      payer[field.name] = field.value;
    });
    return payer;
  },
  payment: function(){
    var payment = {};
    $('#moip-transaction .moip_validator :input').serializeArray().forEach(function(field){
      payment[field.name] = field.value;
    });
    return payment;
  },
  creditcard: {
    calcPlots: function(institution){
      MoIP.settings = {
        cofre: "",
        instituicao: institution,
        callback: "MoIP.creditcard.refreshPlots"
      };
      MoipUtil.calcularParcela(MoIP.settings);
    },
    refreshPlots: function(data){
      const $select = $("#moip_validator_plots");
      $select.find('option').remove();
      console.info("-------------------------------------");
      console.info(data);
      $select.append($('<option>').val("1").text("A Vista"));
      $(data["parcelas"]).toArray().forEach(function(option){
        $select.append($('<option>')
          .val(option["quantidade"])
          .text(option["quantidade"] + "x R$ " + option["valor"] + " = R$ " + option["valor_total"])
        );
      });
    }
  }
};
